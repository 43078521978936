<template>
  <div v-if="showInspectionCamera">
    <InspectionImageCamera
      @hidden-camera="stopImageCamera"
      @capture-camera="setImageCapture"
    />
  </div>
  <div v-else>
    <Navbar>
      <template v-slot:navbarButton>
        <span class="text-white d-block h4 my-auto">{{
          inspection_result.inspection_result_number
        }}</span>
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container pt-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">検査中断</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ inspection_result.inspection_name }}
            </h6>
            <hr />
            <p>
              【<span class="fw-bold">{{
                inspection_result.inspection_result_number
              }}</span
              >】の検査を中断します。現状を撮影してください。
            </p>
            <div class="row gy-4">
              <div class="col-lg-7">
                <div class="position-relative">
                  <ImageViewer
                    ref="imageViewerElem"
                    :file="{
                      file_path: formData.photo,
                      file_type: formData.file_type,
                    }"
                    :show_lightbox="false"
                  />
                  <div
                    v-if="formData.photo"
                    class="position-absolute top-0 end-0 m-2"
                  >
                    <button
                      type="button"
                      class="btn btn-danger btn-sm ms-1"
                      @click.prevent="deleteFile"
                    >
                      <i class="bi bi-trash3"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="row g-2 mb-2">
                  <div class="col-md-6">
                    <button
                      class="btn btn-dark w-100 p-3 me-2"
                      @click.prevent="startImageCamera"
                    >
                      <i class="bi bi-camera me-1"></i>カメラで撮影
                    </button>
                  </div>
                  <div class="col-md-6">
                    <FileSelect
                      ref="fileSelect"
                      @selected-func="fileSelected"
                      :accept="accept"
                    >
                      <button
                        type="button"
                        class="btn btn-light w-100 p-3 me-2"
                        @click.prevent="fileSelect"
                      >
                        <i class="bi bi-folder2 me-1"></i>ファイルを選択
                      </button>
                    </FileSelect>
                  </div>
                </div>
                <label for="description" class="form-label">補足コメント</label>
                <select
                  class="form-select mb-2"
                  id="interrupted_reason_id"
                  @change="insertToDescription($event.target.value)"
                >
                  <option value="">中断理由を選択してください</option>
                  <option
                    v-for="interrupted_reason in interrupted_reasons"
                    :key="interrupted_reason.id"
                    :value="interrupted_reason.reason"
                  >
                    {{ interrupted_reason.reason }}
                  </option>
                </select>
                <textarea
                  v-model="formData.description"
                  class="form-control"
                  id="description"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container pt-5">
        <div class="row">
          <div class="col">
            <button
              class="btn btn-lg btn-primary w-100 py-4"
              v-on:click.prevent="formSubmit()"
            >
              送信
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import FileSelect from '@/components/AppFileSelect.vue'
import ImageViewer from '@/components/AppImageViewer.vue'
import InspectionImageCamera from '@/components/inspection/InspectionImageCamera.vue'
import Navbar from '@/components/inspection/InspectionNavbar.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InspectionResume',
  components: {
    ImageViewer,
    FileSelect,
    Navbar,
    InspectionImageCamera,
  },

  data() {
    return {
      formData: {},
      showInspectionCamera: false,
      accept: 'image/jpeg,image/png',
    }
  },

  computed: {
    ...mapGetters({
      inspection_result: 'inspection_result/detail',
      timeout: 'alert/timeout',
      interrupted_reasons: 'inspection_master/inspection_interrupted_reasons',
    }),
  },

  async created() {
    if (!this.inspection_result.id) {
      this.$store.dispatch('alert/setErrorMessage', {
        message: '不正なアクセスです。',
      })
      window.setTimeout(() => {
        this.$router.push({ name: 'Inspection' })
      }, this.timeout + 500)
    }

    await this.getInspectionInterruptedReasons()

    this.formData = {
      inspection_result_id: this.inspection_result.id,
      type: '中断',
      title: '検査中断',
      is_success: false,
      photo: '',
    }
  },

  methods: {
    ...mapActions({
      postData: 'inspection_item/postData',
      getInspectionInterruptedReasons:
        'inspection_master/fetchInspectionInterruptedReasonList',
    }),
    async formSubmit() {
      await this.postData(this.formData).then(() => {
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '送信しました。',
        })
        window.setTimeout(() => {
          this.$router.push({ name: 'Inspection' })
        }, this.timeout + 500)
      })
    },
    fileSelect() {
      this.$refs.fileSelect.fileEvent()
    },
    fileSelected(file_path, file_type) {
      this.formData.photo = file_path
      this.formData.file_type = file_type
    },
    deleteFile() {
      this.formData.photo = ''
      this.formData.file_type = ''
    },
    setImageCapture(file_path, file_type) {
      this.formData.photo = file_path
      this.formData.file_type = file_type
      this.stopImageCamera()
    },
    startImageCamera() {
      this.showInspectionCamera = true
    },
    stopImageCamera() {
      this.showInspectionCamera = false
    },
    insertToDescription(value) {
      if (!value) {
        return
      }

      if (!this.formData.description) {
        this.formData.description = ''
      }
      this.formData.description += value
    },
  },
}
</script>
