import { createStore } from 'vuex'
import inspection from './inspection/inspection'
import inspection_call from './inspection/inspection_call'
import inspection_item from './inspection/inspection_item'
import inspection_master from './inspection/inspection_master'
import inspection_plan from './inspection/inspection_plan'
import inspection_result from './inspection/inspection_result'
import inspection_temp from './inspection/inspection_temp'
import alert from './modules/alert'
import auth from './modules/auth'
import loading from './modules/loading'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('inspection/clearAll', null, { root: true })
      dispatch('inspection_call/clearAll', null, { root: true })
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_item/clearAll', null, { root: true })
      dispatch('inspection_temp/clearAll', null, { root: true })
      dispatch('inspection_plan/clearAll', null, { root: true })
      dispatch('inspection_master/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('inspection/clearAll', null, { root: true })
      dispatch('inspection_call/clearAll', null, { root: true })
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_item/clearAll', null, { root: true })
      dispatch('inspection_temp/clearAll', null, { root: true })
      dispatch('inspection_plan/clearAll', null, { root: true })
      dispatch('inspection_master/clearAll', null, { root: true })
    },
  },
  modules: {
    auth,
    alert,
    loading,
    inspection,
    inspection_call,
    inspection_result,
    inspection_item,
    inspection_temp,
    inspection_plan,
    inspection_master,
  },
})

export default store
