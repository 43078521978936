<template>
  <Navbar>
    <template v-slot:navbarButton>
      <span class="text-white d-block h4 my-auto">{{
        inspection_result.inspection_result_number
      }}</span>
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">検査中断</h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ inspection_result.inspection_name }}
          </h6>
          <hr />
          <p>
            【<span class="fw-bold">{{
              inspection_result.inspection_result_number
            }}</span
            >】の検査を中断します。現状を撮影してください。
          </p>
          <div class="row gy-4">
            <div class="col-lg-7">
              <Camera @capture-func="setCaptureImage" />
            </div>
            <div class="col-lg-5">
              <label for="description" class="form-label">補足コメント</label>
              <select
                class="form-select mb-2"
                id="interrupted_reason_id"
                @change="insertToDescription($event.target.value)"
              >
                <option value="">中断理由を選択してください</option>
                <option
                  v-for="interrupted_reason in interrupted_reasons"
                  :key="interrupted_reason.id"
                  :value="interrupted_reason.reason"
                >
                  {{ interrupted_reason.reason }}
                </option>
              </select>
              <textarea
                v-model="form_date.description"
                class="form-control"
                id="description"
                rows="5"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <button
            class="btn btn-lg btn-dark w-100 py-4"
            v-on:click.prevent="formSubmit()"
          >
            送信
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from '@/components/inspection/InspectionNavbar.vue';
import Camera from '@/components/TheCamera.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'InspectionResume',
  components: {
    Navbar,
    Camera,
  },

  data() {
    return {
      form_date: {},
    }
  },

  computed: {
    ...mapGetters({
      inspection_result: 'inspection_result/detail',
      timeout: 'alert/timeout',
      interrupted_reasons: 'inspection_master/inspection_interrupted_reasons',
    }),
  },

  async created() {
    if (!this.inspection_result.id) {
      this.$store.dispatch('alert/setErrorMessage', {
        message: '不正なアクセスです。',
      })
      window.setTimeout(() => {
        this.$router.push({ name: 'Inspection' })
      }, this.timeout + 500)
    }

    await this.getInspectionInterruptedReasons()

    this.form_date = {
      inspection_result_id: this.inspection_result.id,
      type: '中断',
      title: '検査中断',
      is_success: false,
      photo: '',
    }
  },

  methods: {
    ...mapActions({
      postData: 'inspection_item/postData',
      getInspectionInterruptedReasons:
        'inspection_master/fetchInspectionInterruptedReasonList',
    }),
    async formSubmit() {
      await this.postData(this.form_date).then(() => {
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '送信しました。',
        })
        window.setTimeout(() => {
          this.$router.push({ name: 'Inspection' })
        }, this.timeout + 500)
      })
    },
    setCaptureImage(dataURL) {
      this.form_date['photo'] = dataURL
    },
    insertToDescription(value) {
      if (!value) {
        return
      }

      if (!this.form_date.description) {
        this.form_date.description = ''
      }
      this.form_date.description += value
    },
  },
}
</script>
