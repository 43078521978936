import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  inspection_interrupted_reasons: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  inspection_interrupted_reasons: (state) => state.inspection_interrupted_reasons,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setInspectionInterruptedReasons: (state, inspection_interrupted_reasons) =>
    (state.inspection_interrupted_reasons = inspection_interrupted_reasons),
  clearInspectionInterruptedReasons: (state) => (state.inspection_interrupted_reasons = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearInspectionInterruptedReasons')
      resolve()
    })
  },
  async fetchInspectionInterruptedReasonList({ state, commit }) {
    if (state.inspection_interrupted_reasons.length > 0) {
      return
    }

    const response = await api.get('operate/inspection/interrupted-reasons/list/')
    commit('setInspectionInterruptedReasons', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
